import React, { useEffect, useState } from 'react';

import SEO from '../blocks/layout/seo';
import FormContainer from '../blocks/layout/formContainer';
import { Collapse, Divider, Progress } from 'antd';
import axios from 'axios';
import { Firebase } from '../config/firebase';
import { CaretRightOutlined } from '@ant-design/icons';
import { getPercentage } from '../config/functions';
import { Note } from '@geist-ui/core';
const { Panel } = Collapse;
const Usage = () => {
    const [totalSubmission, setTotalSubmission] = useState(0);
    const [submissionLimit, setSubmissionLimit] = useState(0);
    const [fileStorageLimit, setFileStorageLimit] = useState(0);
    const [fileStorageUsed, setFileStorageUsed] = useState(0);
    const [formLimitAllow, setFormLimitAllow] = useState(0);
    const [formsCreated, setFormsCreated] = useState(0);
    const [usedSizeString, setUsedSizeString] = useState('');

    async function fetchMyAPI(user) {
        let { data } = await axios.get(
            `${process.env.GATSBY_BASE_URL}/getUsage/${user.uid}`
        );
        if (data !== null) {
            const {
                count,
                reqLimits,
                storageLimit,
                formLimit,
                storageSize,
                totalForm,
                storageSizeString,
            } = data;
            setTotalSubmission(count);
            setSubmissionLimit(reqLimits);
            setFileStorageLimit(storageLimit);
            setFileStorageUsed(storageSize);
            setFormLimitAllow(formLimit);
            setFormsCreated(totalForm);
            setUsedSizeString(storageSizeString);
        }
    }

    useEffect(() => {
        const unsubscribe = Firebase.auth().onAuthStateChanged(fetchMyAPI);

        // unsubscribe to the listener when unmounting
        return () => unsubscribe();
    }, []);

    return (
        <>
            <SEO title="One place for all your forms — FormWithSheet" />
            <FormContainer menuKey="2">
                <h2>Your Usage.</h2>

                <Collapse
                    defaultActiveKey={['1', '2', '3']}
                    expandIcon={({ isActive }) => (
                        <CaretRightOutlined rotate={isActive ? 90 : 0} />
                    )}
                    className="site-collapse-custom-collapse"
                >
                    <Panel
                        header="Form usage"
                        key="1"
                        className="site-collapse-custom-panel"
                    >
                        <Note label={`${totalSubmission}/${submissionLimit}`}>
                            Number of form submissions you made in one month
                        </Note>

                        <Progress
                            strokeLinecap="square"
                            percent={getPercentage(
                                totalSubmission,
                                submissionLimit,
                                0
                            )}
                            strokeColor="black"
                        />
                    </Panel>
                    <Panel
                        header="File Storage"
                        key="2"
                        className="site-collapse-custom-panel"
                    >
                        <Note
                            label={`${usedSizeString}/${fileStorageLimit} GB`}
                        >
                            Number of form submissions you made in one month
                        </Note>
                        <Progress
                            strokeLinecap="square"
                            percent={getPercentage(
                                fileStorageUsed / (1024 * 1024 * 1024),
                                fileStorageLimit,
                                0
                            )}
                            strokeColor="black"
                        />
                    </Panel>
                    <Panel
                        header="Forms Count"
                        key="3"
                        className="site-collapse-custom-panel"
                    >
                        <Note label={`${formsCreated}/${formLimitAllow}`}>
                            Total forms you setup
                        </Note>
                        <Progress
                            strokeLinecap="square"
                            percent={getPercentage(
                                formsCreated,
                                formLimitAllow,
                                0
                            )}
                            strokeColor="black"
                        />
                    </Panel>
                </Collapse>
            </FormContainer>
        </>
    );
};

export default Usage;
